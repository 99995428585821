import PropTypes from "prop-types";
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import Video from "components/Video";
import useMediaQuery from "hooks/useMediaQuery";
import { SM } from "consts/breakpoints";
import "./styles/ModalVideo.scss";

function ModalVideo({ isOpen, toggle }) {
  const isMobile = useMediaQuery(SM);

  return (
    <Modal
      className="modal-lg sampling-request-modal"
      isOpen={isOpen}
      toggle={toggle}
      size="lg"
      centered
    >
      <ModalHeader
        className="custom-modal-header border-0 pb-0"
        toggle={toggle}
      />
      <ModalBody>
        <div className="player-wrapper">
          <Video className="img-fluid" fromYoutube>
            <iframe
              width="100%"
              height={!isMobile ? "464" : "250"}
              src="https://www.youtube.com/embed/bHWfH_AwuvU?autoplay=1"
              title="YouTube video player"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowFullScreen
            />
          </Video>
        </div>
      </ModalBody>
    </Modal>
  );
}

ModalVideo.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  toggle: PropTypes.func.isRequired,
};

export default ModalVideo;
