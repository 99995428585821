import { useState } from "react";
import { useParams } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import { useKeenSlider } from "keen-slider/react";
import CarouselImage from "components/Hero/components/ColorsSlider/components/CarouselImage";
import { COLORS as colors } from "modules/homeAlt/components/ColorPalette/constants";
import "keen-slider/keen-slider.min.css";
import "./styles/ColorsSlider.scss";
import useMediaQuery from "hooks/useMediaQuery";
import { SM } from "consts/breakpoints";

export default function ColorsSlider() {
  const { slug } = useParams();
  const isMobile = useMediaQuery(SM);
  const [currentSlide, setCurrentSlide] = useState(0);
  const [loaded, setLoaded] = useState(false);
  const [sliderRef, slider] = useKeenSlider({
    slides: { perView: 4, spacing: !isMobile && 16 },
    slideChanged(slider) {
      setCurrentSlide(slider.track.details.rel);
    },
    created() {
      setLoaded(true);
    },
  });

  return (
    <div className="colors-slider">
      <h5 className="colors-slider__title text-uppercase">
        <FormattedMessage
          id="Maglia.ColorsSlider.title"
          defaultMessage="DISCOVER THE NEW COLOUR RANGE"
        />
      </h5>
      <div className="colors-slider__carousel">
        <div ref={sliderRef} className="keen-slider">
          {colors.map(({ node: color }) => (
            <div key={`key_${color.slug}`} className="keen-slider__slide">
              <CarouselImage color={color} active={slug === color.slug} />
            </div>
          ))}
        </div>
        {loaded && slider.current && (
          <>
            <ArrowLeft
              onClick={(e) => e.stopPropagation() || slider.current.prev()}
              disabled={currentSlide === 0}
            />
            <ArrowRight
              onClick={(e) => e.stopPropagation() || slider.current.next()}
              disabled={
                currentSlide === slider.current.track.details.slides.length - 1
              }
            />
          </>
        )}
      </div>
    </div>
  );
}

function ArrowLeft(props) {
  const disabeld = props.disabled ? " slider-arrow--disabled" : "";
  return (
    <svg
      onClick={props.onClick}
      className={"slider-arrow slider-arrow--left" + disabeld}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
    >
      <path d="M16.67 0l2.83 2.829-9.339 9.175 9.339 9.167-2.83 2.829-12.17-11.996z" />
    </svg>
  );
}

function ArrowRight(props) {
  const disabeld = props.disabled ? " slider-arrow--disabled" : "";
  return (
    <svg
      onClick={props.onClick}
      className={"slider-arrow slider-arrow--right" + disabeld}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
    >
      <path d="M5 3l3.057-3 11.943 12-11.943 12-3.057-3 9-9z" />
    </svg>
  );
}
