import { FormattedMessage } from "react-intl";
import { Container, Row, Col } from "reactstrap";
import { Divider } from "components/Custom";
import { items, other } from "./utils";
import "./detail.scss";

export default function CardDetail() {
  return (
    <div className="Detail mt-5">
      <Container>
        <Row>
          <Col>
            <h4 className="text-uppercase text-very-dark-cyan fz-title-main">
              <FormattedMessage
                id="Detail.characteristics"
                defaultMessage="CHARACTERISTICS"
              />
            </h4>
          </Col>
        </Row>
        <Divider />

        <Row>
          <Col xs={5} sm={5} md={5} lg={5} xl={5}>
            <div className="w-100 d-flex align-items-center">
              <h5 className="text-very-dark-cyan">{items[0][0]}:</h5>
            </div>
          </Col>
          <Col xs={7} sm={7} md={7} lg={7} xl={7}>
            <h6 className="description">{items[0][1]}</h6>
          </Col>
        </Row>
        <Divider />

        <Row>
          <Col xs={5} sm={5} md={5} lg={5} xl={5}>
            <div className="w-100 d-flex align-items-center">
              <h5 className="text-very-dark-cyan">{items[1][0]}:</h5>
            </div>
          </Col>
          <Col xs={7} sm={7} md={7} lg={7} xl={7}>
            <h6 className="description">{items[1][1]}</h6>
          </Col>
        </Row>
        <Divider />

        <Row>
          <Col xs={5} sm={5} md={5} lg={5} xl={5}>
            <div className="w-100 d-flex align-items-center">
              <h5 className="text-very-dark-cyan">{items[2][0]}:</h5>
            </div>
          </Col>
          <Col xs={7} sm={7} md={7} lg={7} xl={7}>
            <h6 className="description">{items[2][1]}</h6>
          </Col>
        </Row>
        <Divider />

        <Row>
          <Col xs={5} sm={5} md={5} lg={5} xl={5}>
            <div className="w-100 d-flex align-items-center">
              <h5 className="text-very-dark-cyan">{items[3][0]}:</h5>
            </div>
          </Col>
          <Col xs={7} sm={7} md={7} lg={7} xl={7}>
            <h6 className="description">{items[3][1]}</h6>
          </Col>
        </Row>
        <Divider />

        <Row>
          <Col xs={5} sm={5} md={5} lg={5} xl={5}>
            <div className="w-100 d-flex align-items-center">
              <h5 className="text-very-dark-cyan">{items[4][0]}:</h5>
            </div>
          </Col>
          <Col xs={7} sm={7} md={7} lg={7} xl={7}>
            <h6 className="description">{items[4][1]}</h6>
          </Col>
        </Row>
        <Divider />

        <Row>
          <Col xs={5} sm={5} md={5} lg={5} xl={5}>
            <div className="w-100 d-flex align-items-center">
              <h5 className="text-very-dark-cyan">{items[5][0]}:</h5>
            </div>
          </Col>
          <Col xs={7} sm={7} md={7} lg={7} xl={7}>
            <h6 className="description">
              {">"}
              {items[5][1]}
            </h6>
          </Col>
        </Row>
        <Divider />

        <Row>
          <Col xs={5} sm={5} md={5} lg={5} xl={5}>
            <div className="w-100 d-flex align-items-center">
              <h5 className="text-very-dark-cyan">{items[6][0]}:</h5>
            </div>
          </Col>
          <Col xs={7} sm={7} md={7} lg={7} xl={7}>
            <h6 className="description">{items[6][1]}</h6>
          </Col>
        </Row>
        <Divider />

        <Row>
          <Col xs={5} sm={5} md={5} lg={5} xl={5}>
            <div className="w-100 d-flex align-items-center">
              <h5 className="text-very-dark-cyan">{items[7][0]}:</h5>
            </div>
          </Col>
          <Col xs={7} sm={7} md={7} lg={7} xl={7}>
            <h6 className="description">{items[7][1]}</h6>
          </Col>
        </Row>
        <Divider />

        <Row>
          <Col xs={5} sm={5} md={5} lg={5} xl={5}>
            <div className="w-100 d-flex align-items-center">
              <h5 className="text-very-dark-cyan">{other[0][0]}:</h5>
            </div>
          </Col>
          <Col xs={7} sm={7} md={7} lg={7} xl={7}>
            <Container className="px-0">
              {other[0][1].map((el, i) => (
                <Row key={`key_${i}`}>
                  <Col xs={1} sm={1} md={2}>
                    <h6 className="description">{el[0]}</h6>
                  </Col>
                  <Col xs={9} sm={9} md={10}>
                    <h6 className="description">{el[1]}</h6>
                  </Col>
                </Row>
              ))}
            </Container>
          </Col>
        </Row>
        <p className="italic-small text-size-small">
          *
          <FormattedMessage
            id="Detail.thisTerm"
            defaultMessage="This term and any corresponding data refer to the typical performance in the specific tests indicated and should not be construed to imply the behavior of this or any other material under actual fire conditions."
          />
        </p>
        <Divider />

        <Row className="align-items-center">
          <Col xs={6} sm={6} md={6} lg={6} xl={6}>
            <h5 className="text-uppercase text-very-dark-cyan">
              {other[1][0]}
            </h5>
          </Col>
          <Col xs={6} sm={6} md={6} lg={6} xl={6}>
            <div className="my-2 d-flex justify-content-center">
              <img className="images" src={other[1][1]} alt="permablok3" />
              <img className="images" src={other[1][2]} alt="phthalate-free" />
            </div>
          </Col>
        </Row>
        <Divider />

        <Row>
          <Col xs={5} sm={5} md={5} lg={5} xl={5}>
            <div className="w-100 d-flex align-items-center">
              <h5 className="text-very-dark-cyan">{other[2][0]}</h5>
            </div>
          </Col>
          <Col xs={7} sm={7} md={7} lg={7} xl={7}>
            <Container className="px-0">
              <h6 className="description">{other[2][1]}</h6>
              <h6 className="description description--light">{other[2][2]}</h6>
              <h6 className="description">{other[2][3]}</h6>
              <h6 className="description description--light">{other[2][4]}</h6>
            </Container>
          </Col>
        </Row>
        <Divider />

        <Row>
          <Col xs={5} sm={5} md={5} lg={5} xl={5}>
            <div className="w-100 d-flex align-items-center">
              <h5 className="text-very-dark-cyan">{other[3][0]}</h5>
            </div>
          </Col>
          <Col xs={7} sm={7} md={7} lg={7} xl={7}>
            <Container className="px-0">
              <h6 className="description">{other[3][1]}</h6>
            </Container>
          </Col>
        </Row>
        <Divider />

        <Row className="mt-2">
          <Col xs={5} sm={5} md={5} lg={5} xl={5}>
            <div className="w-100 d-flex align-items-center">
              <h5 className="text-uppercase text-very-dark-cyan">
                {other[4][0]}
              </h5>
            </div>
          </Col>
          <Col xs={7} sm={7} md={7} lg={7} xl={7}>
            <Container className="px-0">
              <img
                className="images--ecosense"
                src={other[4][1]}
                alt="ecosense"
              />
              <h6 className="description">{other[4][2]}</h6>
            </Container>
          </Col>
        </Row>
        <Divider />

        <Row>
          <Col xs={5} sm={5} md={5} lg={5} xl={5}>
            <div className="w-100 d-flex align-items-center">
              <h5 className="text-uppercase text-very-dark-cyan">
                {other[5][0]}
              </h5>
            </div>
          </Col>
          <Col xs={7} sm={7} md={7} lg={7} xl={7}>
            <Container className="px-0">
              <h6 className="description">{other[5][1]}</h6>
            </Container>
          </Col>
        </Row>

        <Row>
          <Col>
            <Container className="px-0">
              <h6 className="text-size-small description description--light">
                {other[6][0]}
              </h6>
            </Container>
          </Col>
        </Row>
        <Divider />
      </Container>
    </div>
  );
}
