import { useContext } from "react";
import PropTypes from "prop-types";
import { Row, Col, Container, Modal, ModalHeader, ModalBody } from "reactstrap";
import RequestForm from "./components/RequestForm";
import ModalContext from "./context";
import "./styles/samplingrequest.scss";

function SamplingRequest({ isOpen, toggle }) {
  const context = useContext(ModalContext);

  return (
    <Modal
      className="modal-lg sampling-request-modal"
      scrollable
      isOpen={isOpen}
      toggle={toggle}
    >
      <ModalHeader className="custom-modal-header border-0" toggle={toggle} />
      <ModalBody>
        <Container>
          <Row>
            <Col md={12}>
              <h3 className="text-center">{context.title}</h3>
            </Col>
          </Row>
          {isOpen && <RequestForm toggle={toggle} />}
        </Container>
      </ModalBody>
    </Modal>
  );
}

SamplingRequest.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  toggle: PropTypes.func.isRequired,
};

export default SamplingRequest;
