import arctic from "./img/img_new/1-ARCTIC.jpg";
import uyuni from "./img/img_new/2-UYUNI.jpg";
import oak from "./img/img_new/3-OAK.jpg";
import ginger from "./img/img_new/4-GINGER.jpg";
import granite from "./img/img_new/5-GRANITE.jpg";
import alaska from "./img/img_new/6-ALASKA.jpg";
import ash from "./img/img_new/7-ASH.jpg";
import thunder from "./img/img_new/8-THUNDER.jpg";
import blackcave from "./img/img_new/9-BLACKCAVE.jpg";
import minuit from "./img/img_new/10-MINUIT.jpg";
import primrose from "./img/img_new/11-PRIMROSE.jpg";
import fjord from "./img/img_new/12-FJORD.jpg";
import svalbard from "./img/img_new/13-SVALBARD.jpg";
import kyanite from "./img/img_new/14-KYANITE.jpg";
import indigo from "./img/img_new/15-INDIGO.jpg";
import hive from "./img/img_new/16-HIVE.jpg";
import blossom from "./img/img_new/17-BLOSSOM.jpg";
import mars from "./img/img_new/18-MARS.jpg";
import truffle from "./img/img_new/19-TRUFFLE.jpg";

// WARNING: import for imageThumbSmallSmall
import arcticSmall from "./img/img_small/1-arctic.jpg";
import uyuniSmall from "./img/img_small/2-uyuni.jpg";
import oakSmall from "./img/img_small/3-oak.jpg";
import gingerSmall from "./img/img_small/4-ginger.jpg";
import graniteSmall from "./img/img_small/5-granite.jpg";
import alaskaSmall from "./img/img_small/6-alaska.jpg";
import ashSmall from "./img/img_small/7-ash.jpg";
import thunderSmall from "./img/img_small/8-thunder.jpg";
import blackcaveSmall from "./img/img_small/9-blackcave.jpg";
import minuitSmall from "./img/img_small/10-minuit.jpg";
import primroseSmall from "./img/img_small/11-primrose.jpg";
import fjordSmall from "./img/img_small/12-fjord.jpg";
import svalbardSmall from "./img/img_small/13-svalbard.jpg";
import kyaniteSmall from "./img/img_small/14-kyanite.jpg";
import indigoSmall from "./img/img_small/15-indigo.jpg";
import hiveSmall from "./img/img_small/16-hive.jpg";
import blossomSmall from "./img/img_small/17-blossom.jpg";
import marsSmall from "./img/img_small/18-mars.jpg";
import truffleSmall from "./img/img_small/19-truffle.jpg";

export const COLORS = [
  {
    node: {
      color: "arctic",
      label: "arctic",
      value: "arctic",
      slug: "arctic",
      stock: "MC5-0010",
      image: arctic,
      imageThumbSmall: arcticSmall,
      imageThumbMedium: arctic,
    },
  },
  {
    node: {
      color: "uyuni",
      label: "uyuni",
      value: "uyuni",
      slug: "uyuni",
      stock: "MC5-0002",
      image: uyuni,
      imageThumbSmall: uyuniSmall,
      imageThumbMedium: uyuni,
    },
  },
  {
    node: {
      color: "oak",
      label: "oak",
      value: "oak",
      slug: "oak",
      stock: "MC5-0004",
      image: oak,
      imageThumbSmall: oakSmall,
      imageThumbMedium: oak,
    },
  },
  {
    node: {
      color: "ginger",
      label: "ginger",
      value: "ginger",
      slug: "ginger",
      stock: "MC5-0003",
      image: ginger,
      imageThumbSmall: gingerSmall,
      imageThumbMedium: ginger,
    },
  },
  {
    node: {
      color: "granite",
      label: "granite",
      value: "granite",
      slug: "granite",
      stock: "MC5-0007",
      image: granite,
      imageThumbSmall: graniteSmall,
      imageThumbMedium: granite,
    },
  },
  {
    node: {
      color: "truffle",
      label: "truffle",
      value: "truffle",
      slug: "truffle",
      stock: "MC5-1984",
      image: truffle,
      imageThumbSmall: truffleSmall,
      imageThumbMedium: truffle,
    },
  },
  {
    node: {
      color: "alaska",
      label: "alaska",
      value: "alaska",
      slug: "alaska",
      stock: "MC5-0005",
      image: alaska,
      imageThumbSmall: alaskaSmall,
      imageThumbMedium: alaska,
    },
  },
  {
    node: {
      color: "ash",
      label: "ash",
      value: "ash",
      slug: "ash",
      stock: "MC5-0008",
      image: ash,
      imageThumbSmall: ashSmall,
      imageThumbMedium: ash,
    },
  },
  {
    node: {
      color: "thunder",
      label: "thunder",
      value: "thunder",
      slug: "thunder",
      stock: "MC5-0006",
      image: thunder,
      imageThumbSmall: thunderSmall,
      imageThumbMedium: thunder,
    },
  },
  {
    node: {
      color: "blackcave",
      label: "blackcave",
      value: "blackcave",
      slug: "black_cave",
      stock: "MC5-0012",
      image: blackcave,
      imageThumbSmall: blackcaveSmall,
      imageThumbMedium: blackcave,
    },
  },
  {
    node: {
      color: "minuit",
      label: "minuit",
      value: "minuit",
      slug: "minuit",
      stock: "MC5-0017",
      image: minuit,
      imageThumbSmall: minuitSmall,
      imageThumbMedium: minuit,
    },
  },
  {
    node: {
      color: "primrose",
      label: "primrose",
      value: "primrose",
      slug: "primrose",
      stock: "MC5-0016",
      image: primrose,
      imageThumbSmall: primroseSmall,
      imageThumbMedium: primrose,
    },
  },
  {
    node: {
      color: "fjord",
      label: "fjord",
      value: "fjord",
      slug: "fjord",
      stock: "MC5-0011",
      image: fjord,
      imageThumbSmall: fjordSmall,
      imageThumbMedium: fjord,
    },
  },
  {
    node: {
      color: "svalbard",
      label: "svalbard",
      value: "svalbard",
      slug: "svalbard",
      stock: "MC5-0019",
      image: svalbard,
      imageThumbSmall: svalbardSmall,
      imageThumbMedium: svalbard,
    },
  },
  {
    node: {
      color: "kyanite",
      label: "kyanite",
      value: "kyanite",
      slug: "kyanite",
      stock: "MC5-0018",
      image: kyanite,
      imageThumbSmall: kyaniteSmall,
      imageThumbMedium: kyanite,
    },
  },
  {
    node: {
      color: "indigo",
      label: "indigo",
      value: "indigo",
      slug: "indigo",
      stock: "MC5-0013",
      image: indigo,
      imageThumbSmall: indigoSmall,
      imageThumbMedium: indigo,
    },
  },
  {
    node: {
      color: "hive",
      label: "hive",
      value: "hive",
      slug: "hive",
      stock: "MC5-0014",
      image: hive,
      imageThumbSmall: hiveSmall,
      imageThumbMedium: hive,
    },
  },
  {
    node: {
      color: "blossom",
      label: "blossom",
      value: "blossom",
      slug: "blossom",
      stock: "MC5-0009",
      image: blossom,
      imageThumbSmall: blossomSmall,
      imageThumbMedium: blossom,
    },
  },
  {
    node: {
      color: "mars",
      label: "mars",
      value: "mars",
      slug: "mars",
      stock: "MC5-0015",
      image: mars,
      imageThumbSmall: marsSmall,
      imageThumbMedium: mars,
    },
  },
];
