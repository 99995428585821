export default function emailBody(messages, values) {
  const [
    thankYou,
    intro,
    summaryTitle,
    product,
    customerDetails,
    shippingInformation,
    swatchbook,
    colour,
  ] = messages;
  const {
    request,
    colours,
    firstName,
    lastName,
    companyName,
    email,
    phone,
    country,
    address,
    postalCode,
    city,
  } = values;

  return `
  <!DOCTYPE html>
<html
  xmlns="http://www.w3.org/1999/xhtml"
  xmlns:v="urn:schemas-microsoft-com:vml"
  xmlns:o="urn:schemas-microsoft-com:office:office"
>
  <head>
    <title></title>
    <meta http-equiv="X-UA-Compatible" content="IE=edge" />
    <meta http-equiv="Content-Type" content="text/html; charset=UTF-8" />
    <meta name="viewport" content="width=device-width, initial-scale=1" />
    <style type="text/css">
      #outlook a {
        padding: 0;
      }

      .ReadMsgBody {
        width: 100%;
      }

      .ExternalClass {
        width: 100%;
      }

      .ExternalClass * {
        line-height: 100%;
      }

      body {
        margin: 0;
        padding: 0;
        -webkit-text-size-adjust: 100%;
        -ms-text-size-adjust: 100%;
      }

      table,
      td {
        border-collapse: collapse;
        mso-table-lspace: 0pt;
        mso-table-rspace: 0pt;
      }

      img {
        border: 0;
        height: auto;
        line-height: 100%;
        outline: none;
        text-decoration: none;
        -ms-interpolation-mode: bicubic;
      }

      p {
        display: block;
        margin: 13px 0;
      }
    </style>
    <style type="text/css">
      @media only screen and (max-width: 480px) {
        @-ms-viewport {
          width: 320px;
        }

        @viewport {
          width: 320px;
        }
      }
    </style>
    <link
      href="https://fonts.googleapis.com/css?family=Ubuntu:300,400,500,700"
      rel="stylesheet"
      type="text/css"
    />
    <style type="text/css">
      @import url(https://fonts.googleapis.com/css?family=Ubuntu:300,400,500,700);
    </style>
    <style type="text/css">
      @media only screen and (min-width: 480px) {
        .mj-column-per-50 {
          width: 50% !important;
          max-width: 50%;
        }

        .mj-column-per-100 {
          width: 100% !important;
          max-width: 100%;
        }
      }
    </style>
    <style type="text/css">
      @media only screen and (max-width: 480px) {
        table.full-width-mobile {
          width: 100% !important;
        }

        td.full-width-mobile {
          width: auto !important;
        }
      }
    </style>
  </head>

  <body style="background-color: #eeeeee">
    <div style="background-color: #eeeeee">
      <div
        style="
          background: #00545b;
          background-color: #00545b;
          margin: 0px auto;
          max-width: 600px;
        "
      >
        <table
          align="center"
          border="0"
          cellpadding="0"
          cellspacing="0"
          role="presentation"
          style="background: #00545b; background-color: #00545b; width: 100%"
        >
          <tbody>
            <tr>
              <td
                style="
                  direction: ltr;
                  font-size: 0px;
                  padding: 20px 0;
                  text-align: center;
                "
              >
                <div
                  class="mj-column-per-50 outlook-group-fix"
                  style="
                    font-size: 0px;
                    text-align: left;
                    direction: ltr;
                    display: inline-block;
                    vertical-align: top;
                    width: 100%;
                  "
                >
                  <table
                    border="0"
                    cellpadding="0"
                    cellspacing="0"
                    role="presentation"
                    style="vertical-align: top"
                    width="100%"
                  >
                    <tr>
                      <td
                        align="left"
                        style="
                          font-size: 0px;
                          padding: 10px 25px;
                          word-break: break-word;
                        "
                      >
                        <table
                          border="0"
                          cellpadding="0"
                          cellspacing="0"
                          role="presentation"
                          style="border-collapse: collapse; border-spacing: 0px"
                        >
                          <tbody>
                            <tr>
                              <td style="width: 109px">
                                <a
                                  href="https://spradling.group"
                                  target="_blank"
                                >
                                  <img
                                    height="18"
                                    src="https://spradling-emails.s3-us-west-2.amazonaws.com/images/logo-spradling-header.png"
                                    style="
                                      border: 0;
                                      display: block;
                                      outline: none;
                                      text-decoration: none;
                                      height: 18px;
                                      width: 100%;
                                      font-size: 13px;
                                    "
                                    width="109"
                                  />
                                </a>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </td>
                    </tr>
                  </table>
                </div>
                <div
                  class="mj-column-per-50 outlook-group-fix"
                  style="
                    font-size: 0px;
                    text-align: left;
                    direction: ltr;
                    display: inline-block;
                    vertical-align: top;
                    width: 100%;
                  "
                >
                  <table
                    border="0"
                    cellpadding="0"
                    cellspacing="0"
                    role="presentation"
                    style="vertical-align: top"
                    width="100%"
                  >
                    <tr>
                      <td
                        align="right"
                        style="
                          font-size: 0px;
                          padding: 10px 25px;
                          word-break: break-word;
                        "
                      >
                        <div
                          style="
                            font-family: Ubuntu, Helvetica, Arial, sans-serif;
                            font-size: 13px;
                            line-height: 1.5;
                            text-align: right;
                            color: #ffffff;
                          "
                        >
                          ${thankYou}
                        </div>
                      </td>
                    </tr>
                  </table>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div
        style="
          background: #ffffff;
          background-color: #ffffff;
          margin: 0px auto;
          max-width: 600px;
        "
      >
        <table
          align="center"
          border="0"
          cellpadding="0"
          cellspacing="0"
          role="presentation"
          style="background: #ffffff; background-color: #ffffff; width: 100%"
        >
          <tbody>
            <tr>
              <td
                style="
                  direction: ltr;
                  font-size: 0px;
                  padding: 20px 0;
                  text-align: center;
                "
              >
                <div
                  class="mj-column-per-100 outlook-group-fix"
                  style="
                    font-size: 0px;
                    text-align: left;
                    direction: ltr;
                    display: inline-block;
                    vertical-align: top;
                    width: 100%;
                  "
                >
                  <table
                    border="0"
                    cellpadding="0"
                    cellspacing="0"
                    role="presentation"
                    style="vertical-align: top"
                    width="100%"
                  >
                    <tr>
                      <td
                        align="left"
                        style="
                          font-size: 0px;
                          padding: 10px 25px;
                          word-break: break-word;
                        "
                      >
                        <div
                          style="
                            font-family: Ubuntu, Helvetica, Arial, sans-serif;
                            font-size: 13px;
                            line-height: 1.5;
                            text-align: left;
                            color: #6b6b6b;
                          "
                        >
                          ${intro}<br />
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td
                        align="left"
                        style="
                          font-size: 0px;
                          padding: 10px 25px;
                          word-break: break-word;
                        "
                      >
                        <div
                          style="
                            font-family: Ubuntu, Helvetica, Arial, sans-serif;
                            font-size: 13px;
                            line-height: 1;
                            text-align: left;
                            color: #01545b;
                          "
                        >
                          <h3>${summaryTitle}:</h3>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td
                        align="left"
                        style="
                          font-size: 0px;
                          padding: 10px 25px;
                          word-break: break-word;
                        "
                      >
                        <table
                          cellpadding="0"
                          cellspacing="0"
                          width="100%"
                          border="0"
                          style="
                            color: #000000;
                            font-family: Ubuntu, Helvetica, Arial, sans-serif;
                            font-size: 13px;
                            line-height: 22px;
                            table-layout: auto;
                            width: 100%;
                            border: none;
                          "
                        >
                          <tr
                            style="
                              border-bottom: 1px solid #ecedee;
                              text-align: left;
                              padding: 15px 0;
                              background-color: #d8d8d8;
                              color: black;
                            "
                          >
                            <th style="padding: 10px 15px">${product}</th>
                          </tr>
                          <tr style="border-bottom: 1px solid #979797">
                            <td style="padding: 10px 15px">
                              ${
                                request === "DinA4 Samples"
                                  ? colours
                                  : request === "Swatchbook"
                                  ? swatchbook
                                  : colour
                              }
                            </td>
                          </tr>
                        </table>
                      </td>
                    </tr>
                    <tr>
                      <td
                        align="left"
                        style="
                          font-size: 0px;
                          padding: 10px 25px;
                          word-break: break-word;
                        "
                      >
                        <div
                          style="
                            font-family: Ubuntu, Helvetica, Arial, sans-serif;
                            font-size: 13px;
                            line-height: 1;
                            text-align: left;
                            color: #01545b;
                          "
                        >
                          <h4>${customerDetails}</h4>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td
                        align="left"
                        style="
                          font-size: 0px;
                          padding: 10px 25px;
                          word-break: break-word;
                        "
                      >
                        <div
                          style="
                            font-family: Ubuntu, Helvetica, Arial, sans-serif;
                            font-size: 13px;
                            line-height: 1.5;
                            text-align: left;
                            color: #6b6b6b;
                          "
                        >
                          ${firstName} ${lastName}<br />
                          ${email}<br />
                          ${phone}<br />
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td
                        align="left"
                        style="
                          font-size: 0px;
                          padding: 10px 25px;
                          word-break: break-word;
                        "
                      >
                        <div
                          style="
                            font-family: Ubuntu, Helvetica, Arial, sans-serif;
                            font-size: 13px;
                            line-height: 1;
                            text-align: left;
                            color: #01545b;
                          "
                        >
                          <h4>${shippingInformation}</h4>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td
                        align="left"
                        style="
                          font-size: 0px;
                          padding: 10px 25px;
                          word-break: break-word;
                        "
                      >
                        <div
                          style="
                            font-family: Ubuntu, Helvetica, Arial, sans-serif;
                            font-size: 13px;
                            line-height: 1.5;
                            text-align: left;
                            color: #6b6b6b;
                          "
                        >
                          ${firstName} ${lastName}<br />
                          ${companyName}<br />
                          ${address}<br />
                          ${postalCode}<br />
                          ${city}<br />
                          ${country}<br />
                          ${phone}<br />
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td
                        align="center"
                        style="
                          font-size: 0px;
                          padding: 10px 25px;
                          word-break: break-word;
                        "
                      >
                        <div
                          style="
                            font-family: Ubuntu, Helvetica, Arial, sans-serif;
                            font-size: 13px;
                            font-weight: bold;
                            line-height: 1.5;
                            text-align: center;
                            color: #6b6b6b;
                          "
                        >
                          Spradling Europe
                        </div>
                      </td>
                    </tr>
                  </table>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div
        style="
          background: #00545b;
          background-color: #00545b;
          margin: 0px auto;
          max-width: 600px;
        "
      >
        <table
          align="center"
          border="0"
          cellpadding="0"
          cellspacing="0"
          role="presentation"
          style="background: #00545b; background-color: #00545b; width: 100%"
        >
          <tbody>
            <tr>
              <td
                style="
                  direction: ltr;
                  font-size: 0px;
                  padding: 20px 0;
                  text-align: center;
                "
              >
                <div
                  class="mj-column-per-100 outlook-group-fix"
                  style="
                    font-size: 0px;
                    text-align: left;
                    direction: ltr;
                    display: inline-block;
                    vertical-align: top;
                    width: 100%;
                  "
                >
                  <table
                    border="0"
                    cellpadding="0"
                    cellspacing="0"
                    role="presentation"
                    style="vertical-align: top"
                    width="100%"
                  >
                    <tr>
                      <td
                        align="center"
                        style="
                          font-size: 0px;
                          padding: 10px 25px;
                          padding-top: 20px;
                          word-break: break-word;
                        "
                      >
                        <table
                          align="center"
                          border="0"
                          cellpadding="0"
                          cellspacing="0"
                          role="presentation"
                          style="float: none; display: inline-table"
                        >
                          <tr>
                            <td style="padding: 4px">
                              <table
                                border="0"
                                cellpadding="0"
                                cellspacing="0"
                                role="presentation"
                                style="
                                  background: transparent;
                                  border-radius: 3px;
                                  width: 30px;
                                "
                              >
                                <tr>
                                  <td
                                    style="
                                      font-size: 0;
                                      height: 30px;
                                      vertical-align: middle;
                                      width: 30px;
                                    "
                                  >
                                    <a
                                      href="https://www.facebook.com/SpradlingEurope"
                                      target="_blank"
                                    >
                                      <img
                                        height="30"
                                        src="https://www.mailjet.com/images/theme/v1/icons/ico-social/facebook.png"
                                        style="
                                          border-radius: 3px;
                                          display: block;
                                        "
                                        width="30"
                                      />
                                    </a>
                                  </td>
                                </tr>
                              </table>
                            </td>
                          </tr>
                        </table>
                        <table
                          align="center"
                          border="0"
                          cellpadding="0"
                          cellspacing="0"
                          role="presentation"
                          style="float: none; display: inline-table"
                        >
                          <tr>
                            <td style="padding: 4px">
                              <table
                                border="0"
                                cellpadding="0"
                                cellspacing="0"
                                role="presentation"
                                style="
                                  background: transparent;
                                  border-radius: 3px;
                                  width: 30px;
                                "
                              >
                                <tr>
                                  <td
                                    style="
                                      font-size: 0;
                                      height: 30px;
                                      vertical-align: middle;
                                      width: 30px;
                                    "
                                  >
                                    <a
                                      href="https://www.instagram.com/spradlingeurope/"
                                      target="_blank"
                                    >
                                      <img
                                        height="30"
                                        src="https://www.mailjet.com/images/theme/v1/icons/ico-social/instagram.png"
                                        style="
                                          border-radius: 3px;
                                          display: block;
                                        "
                                        width="30"
                                      />
                                    </a>
                                  </td>
                                </tr>
                              </table>
                            </td>
                          </tr>
                        </table>
                        <table
                          align="center"
                          border="0"
                          cellpadding="0"
                          cellspacing="0"
                          role="presentation"
                          style="float: none; display: inline-table"
                        >
                          <tr>
                            <td style="padding: 4px">
                              <table
                                border="0"
                                cellpadding="0"
                                cellspacing="0"
                                role="presentation"
                                style="
                                  background: transparent;
                                  border-radius: 3px;
                                  width: 30px;
                                "
                              >
                                <tr>
                                  <td
                                    style="
                                      font-size: 0;
                                      height: 30px;
                                      vertical-align: middle;
                                      width: 30px;
                                    "
                                  >
                                    <a
                                      href="https://www.linkedin.com/company/spradling-europe"
                                      target="_blank"
                                    >
                                      <img
                                        height="30"
                                        src="https://www.mailjet.com/images/theme/v1/icons/ico-social/linkedin.png"
                                        style="
                                          border-radius: 3px;
                                          display: block;
                                        "
                                        width="30"
                                      />
                                    </a>
                                  </td>
                                </tr>
                              </table>
                            </td>
                          </tr>
                        </table>
                      </td>
                    </tr>
                  </table>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </body>
</html>
  `;
}
