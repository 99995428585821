import {
  ApolloClient,
  ApolloLink,
  HttpLink,
  InMemoryCache,
  concat,
} from "@apollo/client";
import { relayStylePagination } from "@apollo/client/utilities";
import { getCurrentLanguage } from "services";

const uri = `${process.env.REACT_APP_SERVER_URL}/graphql/`;
const httpLink = new HttpLink({ uri });

const updateHeadersMiddleware = new ApolloLink((operation, forward) => {
  const [lang] = getCurrentLanguage();

  operation.setContext(({ headers = {} }) => ({
    headers: {
      ...headers,
      "Accept-Language": lang,
    },
  }));

  return forward(operation);
});

const client = new ApolloClient({
  link: concat(updateHeadersMiddleware, httpLink),
  cache: new InMemoryCache({
    typePolicies: {
      Query: {
        fields: {
          countries: relayStylePagination(["query"]),
        },
      },
    },
  }),
});

export default client;
