import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import Home from "modules/home";
import NotFound from "modules/not-found";
import ConnectedIntlProvider from "intl/ConnectedIntlProvider";
import { getCurrentLanguage } from "services";
import Detail from "modules/detail";
// import ValidateLangURL from "./components/ValidateLangURL";

function RouteManager() {
  const lang = getCurrentLanguage();

  return (
    <ConnectedIntlProvider locale={lang === "" ? "en" : lang}>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Navigate to="/en" replace={true} />} />
          {/* <Route path="/:lang" element={<ValidateLangURL />} /> */}
          <Route path="/:lang" element={<Home />} />
          <Route path="/:lang/detail/:slug" element={<Detail />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </BrowserRouter>
    </ConnectedIntlProvider>
  );
}

export default RouteManager;
