import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import { Col, Container, Modal, ModalBody, ModalHeader, Row } from "reactstrap";
import permablok3Logo from "./img/permablock3.svg";
import layoutsPermablok3 from "./img/layouts-permablok3.jpg";
import graphicPermablok3 from "./img/graphic-permablok3.jpg";
import "./styles/detailModal.scss";

const DetailModalPermablok = ({ isOpen, toggle }) => {
  return (
    <Modal
      className="modal-lg sampling-request-modal"
      scrollable
      isOpen={isOpen}
      toggle={toggle}
    >
      <ModalHeader className="custom-modal-header border-0" toggle={toggle} />
      <ModalBody>
        <Container>
          <Row>
            <Col md={12}>
              <div className="mx-3 text-center mb-4">
                <img
                  className="img-fluid"
                  width={"48%"}
                  src={permablok3Logo}
                  alt="permablok3"
                />
              </div>
            </Col>
          </Row>
          <Row className="mx-lg-3 px-lg-3 my-4">
            <Col md={12}>
              <h3 className="color-very-dark-cyan text-capitalize text-center mb-4">
                <FormattedMessage
                  id="ModalPermablok3.forCoatedFabrics.subtitle"
                  defaultMessage="Superior Protection for Coated Fabrics"
                  values={{
                    sup: (...chunks) => <sup>{chunks}</sup>,
                    strong: (...chunks) => <strong>{chunks}</strong>,
                  }}
                />
              </h3>
              <h6 className="modal-text text-justify">
                <FormattedMessage
                  id="ModalPermablok3.forCoatedFabrics.description"
                  defaultMessage="Spradling<sup>®</sup>’s Coated Fabrics have always offered superior <strong>protection against germs, stains and abrasion!</strong> Thinking of the most frequent problems encountered in the healthcare, hospitality, and mass transportation sectors, we engineered <strong>PERMABLOK<sup>3®</sup></strong>, a resilient and permament protection system that ensures hygienic spaces."
                  values={{
                    sup: (...chunks) => <sup>{chunks}</sup>,
                    strong: (...chunks) => <strong>{chunks}</strong>,
                  }}
                />
              </h6>
            </Col>
            <Col md={12}>
              <div className="mx-3 text-center mb-4">
                <img
                  className="img-fluid w-64"
                  src={layoutsPermablok3}
                  alt="layouts Permablok3"
                />
              </div>
            </Col>
          </Row>
          <Row className="mx-lg-3 px-lg-3 my-4">
            <Col md={12}>
              <h3 className="color-very-dark-cyan text-capitalize text-center mb-4">
                <FormattedMessage
                  id="ModalPermablok3.highAbrasionResistance.subtitle"
                  defaultMessage="high abrasion resistance"
                  values={{
                    sup: (...chunks) => <sup>{chunks}</sup>,
                    strong: (...chunks) => <strong>{chunks}</strong>,
                  }}
                />
              </h3>
              <h6 className="modal-text text-justify">
                <FormattedMessage
                  id="ModalPermablok3.highAbrasionResistance.description"
                  defaultMessage="Increasing durability to ensure that your upholstered furniture continues to look like new, <strong>PERMABLOK<sup>3®</sup></strong> remains highly resistant to deterioration such as cracking, splitting, and loss of flexibility."
                  values={{
                    sup: (...chunks) => <sup>{chunks}</sup>,
                    strong: (...chunks) => <strong>{chunks}</strong>,
                  }}
                />
              </h6>
            </Col>
          </Row>
          <Row className="mx-lg-3 px-lg-3 my-4">
            <Col md={12}>
              <h3 className="color-very-dark-cyan text-capitalize text-center mb-4">
                <FormattedMessage
                  id="ModalPermablok3.antiStainProtection.subtitle"
                  defaultMessage="superior anti-stain protection"
                  values={{
                    sup: (...chunks) => <sup>{chunks}</sup>,
                    strong: (...chunks) => <strong>{chunks}</strong>,
                  }}
                />
              </h3>
              <h6 className="modal-text text-justify">
                <FormattedMessage
                  id="ModalPermablok3.antiStainProtection.description"
                  defaultMessage="<strong>PERMABLOK<sup>3®</sup></strong> makes Spradling<sup>®</sup>’s Coated Fabrics an easy-to-clean upholstery solution that helps to wipe out the most common stains like grease, blood, suntan lotion, crayon, ketchup, black felt tip pens, and other stubborn stains that occur in public spaces."
                  values={{
                    sup: (...chunks) => <sup>{chunks}</sup>,
                    strong: (...chunks) => <strong>{chunks}</strong>,
                  }}
                />
              </h6>
            </Col>
          </Row>
          <Row className="mx-lg-3 px-lg-3 my-4">
            <Col md={12}>
              <h3 className="color-very-dark-cyan text-capitalize text-center mb-4">
                <FormattedMessage
                  id="ModalPermablok3.protectionGerms.subtitle"
                  defaultMessage="superior protection against germs"
                  values={{
                    sup: (...chunks) => <sup>{chunks}</sup>,
                    strong: (...chunks) => <strong>{chunks}</strong>,
                  }}
                />
              </h3>
              <h6 className="modal-text text-justify">
                <FormattedMessage
                  id="ModalPermablok3.protectionGerms.description"
                  defaultMessage="Using Spradling<sup>®</sup>’s Coated Fabrics with <strong>PERMABLOK<sup>3®</sup></strong> protective system, germs counts can be kept to a minimum. It also guards against the surface growth of fungus, mould, and mildew spores which can cause unpleasant odours and allergic reactions. With its unsurpassed <strong>antifungal protection</strong>, Spradling<sup>®</sup>’s Coated Fabrics are also resistant <strong>against gram-positive, gram-negative bacteria, filamentation and yeast</strong>."
                  values={{
                    sup: (...chunks) => <sup>{chunks}</sup>,
                    strong: (...chunks) => <strong>{chunks}</strong>,
                  }}
                />
              </h6>
            </Col>
          </Row>
          <Row className="mx-lg-3 px-lg-3 my-4">
            <Col md={12}>
              <h3 className="text-capitalize mb-4">
                <FormattedMessage
                  id="ModalPermablok3.virucidalActivity.subtitle"
                  defaultMessage="Virucidal Activity"
                />
              </h3>
              <h6 className="modal-text text-justify">
                <FormattedMessage
                  id="ModalPermablok3.virucidalActivity.description"
                  defaultMessage="<strong>PERMABLOK<sup>3®</sup></strong> is an effective barrier against the virus as it is certified with:"
                  values={{
                    sup: (...chunks) => <sup>{chunks}</sup>,
                    strong: (...chunks) => <strong>{chunks}</strong>,
                  }}
                />
              </h6>
              <ul>
                <li className="text-size-small fw-light">
                  <FormattedMessage
                    id="ModalPermablok3.virucidalActivity.ISO181841"
                    defaultMessage="<strong>ISO 181841</strong><sup>1</sup>: reduction of Coronavirus* presence by more than 90% within one hour	 of contact."
                    values={{
                      sup: (...chunks) => <sup>{chunks}</sup>,
                      strong: (...chunks) => <strong>{chunks}</strong>,
                    }}
                  />
                </li>
                <li className="text-size-small fw-light">
                  <FormattedMessage
                    id="ModalPermablok3.virucidalActivity.ISO217022"
                    defaultMessage="<strong>ISO 217022</strong><sup>2</sup>: reduction of Coronavirus* activity by 99,9% within 24 hours of exposure on the surface."
                    values={{
                      sup: (...chunks) => <sup>{chunks}</sup>,
                      strong: (...chunks) => <strong>{chunks}</strong>,
                    }}
                  />
                </li>
              </ul>
              <p className="text-size-extra-small fw-light">
                <FormattedMessage
                  id="ModalPermablok3.virucidalActivity.info"
                  defaultMessage="1. Determination of antiviral activity of textile products{br}2. Measurement of antiviral activity on plastics and other non-porous surfaces.{br}* Testing was conducted with material exposed to Feline Coronavirus (same coronaviridae family, structures, and mechanisms similar to SARS-Cov2). The results refer to the typical performance based on specific tests carried out under laboratory conditions and should not be construed to imply the behaviour of the product under real usage conditions."
                  values={{
                    br: <br />,
                  }}
                />
              </p>
            </Col>
          </Row>
          <Row className="mx-lg-3 px-lg-3 my-4">
            <Col md={12}>
              <h3 className="text-capitalize mb-4">
                <FormattedMessage
                  id="ModalPermablok3.BacteriostaticProp.subtitle"
                  defaultMessage="Bacteriostatic Properties"
                />
              </h3>
              <h6 className="modal-text text-justify">
                <FormattedMessage
                  id="ModalPermablok3.BacteriostaticProp.description"
                  defaultMessage="<strong>PERMABLOK<sup>3®</sup></strong> ensures free from bacteria upholstered surfaces as it is certified with:"
                  values={{
                    sup: (...chunks) => <sup>{chunks}</sup>,
                    strong: (...chunks) => <strong>{chunks}</strong>,
                  }}
                />
              </h6>
              <p className="text-size-small fw-light">
                <FormattedMessage
                  id="ModalPermablok3.BacteriostaticProp.ISO22196"
                  defaultMessage="<strong>ISO 22196</strong>: Value of antibacterial** activity R>2."
                  values={{
                    strong: (...chunks) => <strong>{chunks}</strong>,
                  }}
                />
              </p>
            </Col>
            <Col md={12}>
              <div className="mx-3 text-center">
                <img
                  className="img-fluid w-64"
                  src={graphicPermablok3}
                  alt="graphic permablok3"
                />
              </div>
              <p className="text-size-extra-small fw-light text-center">
                <FormattedMessage
                  id="ModalPermablok3.BacteriostaticProp.image.info"
                  defaultMessage="*B = ufc/cm<sup>2</sup> Control sample | C= ufc/cm<sup>2</sup> Permablok<sup>3®</sup> sample"
                  values={{
                    sup: (...chunks) => <sup>{chunks}</sup>,
                  }}
                />
              </p>
              <p className="text-size-extra-small fw-light">
                <FormattedMessage
                  id="ModalPermablok3.BacteriostaticProp.info"
                  defaultMessage="** Testing was conducted with material exposed to Staphylococcus aureus, Staphylococcus aureus (MRSA), Escherichia coli, and Klebsiella pneumoniae. The results refer to the typical performance based on specific tests carried out under laboratory conditions and should not be construed to imply the behaviour of the product under real usage conditions."
                  values={{
                    br: <br />,
                  }}
                />
              </p>
            </Col>
          </Row>
          <div className="text-center">
            <button
              className="btn btn-dark rounded-pill mt-5 px-5"
              onClick={toggle}
            >
              <FormattedMessage id="Ecosense.close" defaultMessage="Close" />
            </button>
          </div>
        </Container>
      </ModalBody>
    </Modal>
  );
};

DetailModalPermablok.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  toggle: PropTypes.func.isRequired,
};

export default DetailModalPermablok;
