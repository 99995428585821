import ModalVideo from "components/ModalVideo";
import { getCurrentLanguage } from "services";
import previewDe from "./img/de_preview.jpg";
import previewEn from "./img/en_preview_c5.jpg";
import previewEs from "./img/es_preview.jpg";
// import previewFr from "./img/fr_preview.jpg";
import previewIt from "./img/it_preview.jpg";
import previewPl from "./img/pl_preview.jpg";
import "./styles/videoPreview.scss";
import useToggle from "hooks/useToggle";

const PREVIEW = {
  de: previewDe,
  en: previewEn,
  es: previewEs,
  // fr: previewFr,
  fr: previewEn,
  it: previewIt,
  pl: previewPl,
};

export default function VideoPreview() {
  const [toggable, toggle] = useToggle();

  return (
    <>
      <div
        className="video-preview"
        onClick={toggle}
        style={{
          backgroundImage: `url(${PREVIEW[getCurrentLanguage()]})`,
        }}
      />
      <ModalVideo isOpen={toggable} toggle={toggle} />
    </>
  );
}
