import PropTypes from "prop-types";
import { COLORS as colors } from "modules/homeAlt/components/ColorPalette/constants";
import bannerMaglia from "./img/banner.jpg";

export function getColorBySlug(slugSelector) {
  const { node: color } = colors.find(
    ({ node: { slug } }) => slug === slugSelector
  );
  return color;
}

function BackgroundImage({ classes, slug, children }) {
  return (
    <div
      className={classes}
      style={{
        backgroundImage: `url(${
          !slug ? bannerMaglia : getColorBySlug(slug).image
        })`,
      }}
    >
      {children}
    </div>
  );
}

BackgroundImage.propTypes = {
  classes: PropTypes.string,
  slug: PropTypes.string,
  children: PropTypes.node,
};

export default BackgroundImage;
