import { FormattedMessage } from "react-intl";
import permablok3 from "../img/permablock3.svg";
import phthalateFree from "components/Ecosense/components/DetailModal/img/phthalate-free.svg";
import logoEcosense from "components/Ecosense/components/DetailModal/img/logo_ecosense.svg";

export const items = [
  [
    <FormattedMessage
      id="Detail.itemComposition"
      defaultMessage="Composition"
    />,
    <FormattedMessage
      id="Detail.itemCoating"
      defaultMessage="Coating: Vinyl"
    />,
  ],
  [
    <FormattedMessage id="Detail.itemBacking" defaultMessage="Backing" />,
    <FormattedMessage
      id="Detail.item100Poly"
      defaultMessage="100% Polyester"
    />,
  ],
  [
    <FormattedMessage id="Detail.itemWeight" defaultMessage="Weight" />,
    <FormattedMessage id="Detail.item665" defaultMessage="665 gr/m2" />,
  ],
  [
    <FormattedMessage id="Detail.itemWidth" defaultMessage="Width" />,
    <FormattedMessage id="Detail.itemMin" defaultMessage="min. 137 cm" />,
  ],
  [
    <FormattedMessage id="Detail.itemRoll" defaultMessage="Roll size" />,
    <FormattedMessage id="Detail.itemMaomeno" defaultMessage="+/- 30m" />,
  ],
  [
    <FormattedMessage id="Detail.itemAbrasion" defaultMessage="Abrasion" />,
    <FormattedMessage
      id="Detail.item100"
      defaultMessage="100.000 cycles (MARTINDALE)"
    />,
  ],
  [
    <FormattedMessage id="Detail.itemCold" defaultMessage="Cold Crack" />,
    <FormattedMessage id="Detail.item29" defaultMessage="-29°C" />,
  ],
  [
    <FormattedMessage id="Detail.itemUV" defaultMessage="UV-Resistence" />,
    <span>
      <FormattedMessage
        id="Detail.b02"
        defaultMessage="ISO 105 B02: ≥ 7/8 (Blue Wool Scale)"
      />
    </span>,
  ],
];

export const other = [
  [
    <FormattedMessage
      id="Detail.otherTitle"
      defaultMessage="Flame retardancy*"
    />,
    [
      [
        "AT",
        <FormattedMessage
          id="Detail.ÖNORM-A"
          defaultMessage="ÖNORM A 3800 Teil 1, Qualmbildungsklasse Q1 - schwachqualmend"
        />,
      ],
      [
        "AT",
        <FormattedMessage
          id="Detail.ÖNORM-B"
          defaultMessage="ÖNORM B 3825, Gruppe 1 - Schwerbrennbares Verhalten"
        />,
      ],
      [
        "DE",
        <FormattedMessage id="Detail.DIN4102B2" defaultMessage="DIN 4102 B2" />,
      ],
      [
        "ES",
        <FormattedMessage
          id="Detail.ES"
          defaultMessage="UNE 23.727-90 1R/M.2"
        />,
      ],
      [
        "EU",
        <FormattedMessage id="Detail.EN" defaultMessage="EN 1021 Part 1 & 2" />,
      ],
      [
        "FR",
        <FormattedMessage
          id="Detail.FR.NF-D"
          defaultMessage="NF D 60-013 (art. AM 18)"
        />,
      ],
      [
        "FR",
        <FormattedMessage
          id="Detail.FR.NF-P"
          defaultMessage="NF P 92-503/M2"
        />,
      ],
      [
        "IT",
        <FormattedMessage
          id="Detail.IT"
          defaultMessage="UNI 9175 (1987) / UNI 9175/FA1 (1994) Classe 1.IM (UNO I EMME)"
        />,
      ],
      [
        "UK",
        <FormattedMessage
          id="Detail.UK.BS.5852"
          defaultMessage="BS 5852 Part 2 CRIB 5"
        />,
      ],
      [
        "UK",
        <FormattedMessage
          id="Detail.UK.BS.7176"
          defaultMessage="BS 7176 Medium Hazard"
        />,
      ],
      [
        "US",
        <FormattedMessage
          id="Detail.US.CAL"
          defaultMessage="CAL TB 117-2013, Section E"
        />,
      ],
      [
        "US",
        <FormattedMessage id="Detail.US.FAR" defaultMessage="FAR 25/853" />,
      ],
      [
        "US",
        <FormattedMessage id="Detail.US.FMVSS" defaultMessage="FMVSS 302" />,
      ],
      [
        "US",
        <FormattedMessage
          id="Detail.US.NFPA"
          defaultMessage="NFPA 260 - Class 1"
        />,
      ],
    ],
  ],
  [
    <FormattedMessage
      id="Detail.finishes"
      defaultMessage="FINISHES AND TREATMENTS"
    />,
    permablok3,
    phthalateFree,
  ],
  [
    <FormattedMessage
      id="Detail.virucidalActivity"
      defaultMessage="Virucidal Activity"
    />,
    <FormattedMessage
      id="Detail.virucidalActivity.ISO18184"
      defaultMessage="<strong>ISO 18184:2019<sup>1</sup>:</strong>"
      values={{
        sup: (...chunks) => <sup>{chunks}</sup>,
        strong: (...chunks) => <strong>{chunks}</strong>,
      }}
    />,
    <FormattedMessage
      id="Detail.virucidalActivity.ISO18184.description"
      defaultMessage="Coated Fabrics treated with PERMABLOK<sup>3®</sup> are proven to reduce the presence of Coronavirus** by more than 90% within one hour of contact."
      values={{
        sup: (...chunks) => <sup>{chunks}</sup>,
      }}
    />,
    <FormattedMessage
      id="Detail.virucidalActivity.ISO21702"
      defaultMessage="<strong>ISO 21702:2019<sup>2</sup>:</strong>"
      values={{
        sup: (...chunks) => <sup>{chunks}</sup>,
        strong: (...chunks) => <strong>{chunks}</strong>,
      }}
    />,
    <FormattedMessage
      id="Detail.virucidalActivity.ISO21702.description"
      defaultMessage="All the Coated fabrics treated with PERMABLOK<sup>3®</sup> are capable of reducing the Coronavirus** activity by 99,9% within 24 hours of exposure on the surface."
      values={{
        sup: (...chunks) => <sup>{chunks}</sup>,
      }}
    />,
  ],
  [
    <FormattedMessage
      id="Detail.bacteriostaticProperties"
      defaultMessage="Bacteriostatic Properties"
    />,
    <FormattedMessage
      id="Detail.bacteriostaticProperties.description"
      defaultMessage="ISO 22196:201 I.Value of antibacterial*** activity R>2."
    />,
  ],
  [
    <FormattedMessage
      id="Detail.sustainability"
      defaultMessage="sustainability"
    />,
    logoEcosense,
    <FormattedMessage
      id="Detail.sustainability.description"
      defaultMessage="ISO 1400 I Enviromental Management{br}ISO 45001 International Standard for{br}Health and Safety at Work{br}Carbon Neutral | REACH | RoHS"
      values={{
        br: <br />,
      }}
    />,
  ],
  [
    <FormattedMessage
      id="Detail.warranty"
      defaultMessage="warranty<sup>3</sup>"
      values={{
        sup: (...chunks) => <sup>{chunks}</sup>,
      }}
    />,
    <FormattedMessage
      id="Detail.warranty.description"
      defaultMessage="5 years"
    />,
  ],
  [
    <FormattedMessage
      id="Detail.footnote"
      defaultMessage="1. Determination of antiviral activity of textile products{br}2. Measurement of antiviral activity on plastics and other non-porous surfaces.{br}** Testing was conducted with material exposed to Feline Coronavirus (same coronaviridae family, structures anc mechanisms similar to SARS-Cov2){br}*** Testing was conducted with material exposed to Staphylococcus aureus, Staphylococcus aureus (MRSA){br}Escherichia coli and Klebsiella pneumonia.{br}3. Terms and conditions apply Warranty conditions are displayed on our website."
      values={{
        br: <br />,
      }}
    />,
  ],
];
