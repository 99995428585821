import germsBgPermablokMain from "./img/germs_permablok3.jpg";
import permablok3Logo from "./img/permablock3.svg";
import "./styles/permacool.scss";
import DetailModalPermablok from "./components/DetailModalPermablok";
import { FormattedMessage } from "react-intl";
import useToggle from "hooks/useToggle";

export default function Permablok3() {
  const [toggable, toggle] = useToggle();

  return (
    <>
      <DetailModalPermablok isOpen={toggable} toggle={toggle} />
      <div className="Permablok">
        <div className="description">
          <h4 className="mt-3 text-center fw-bold">
            Permablok<sup>³®</sup>
          </h4>
          <div className="container-permablok3 cursor-pointer" onClick={toggle}>
            <img
              className="img-fluid z-1"
              src={germsBgPermablokMain}
              alt="permacool"
            />
            <img
              className="img-fluid position-absolute z-2 translate-middle image-permablok3"
              width={"33%"}
              src={permablok3Logo}
              alt="permacool"
            />
          </div>
          <div className="text-center">
            <button
              className="btn btn-dark rounded-pill my-4 px-3 text-uppercase"
              onClick={toggle}
            >
              <FormattedMessage
                id="Permacool.readMore"
                defaultMessage="Read more"
              />
            </button>
          </div>
        </div>
      </div>
    </>
  );
}
