import { defineMessages } from "react-intl";

const validationMessages = defineMessages({
  required: {
    id: "FormMessages.required",
    description: "Required message for form validation",
    defaultMessage: "Este campo es requerido",
  },
  email: {
    id: "FormMessages.invalidEmail",
    description: "Invalid email message for form validation",
    defaultMessage: "Dirección de correo inválida",
  },
});

export default validationMessages;
