import PropTypes from "prop-types";
import { useParams } from "react-router";
import { Link } from "react-router-dom";
import "./styles/CarouselImage.scss";

function CarouselImage({ color, active }) {
  const { lang } = useParams();

  return (
    <Link
      className={`carousel-image ${active && "carousel-image--active"}`}
      to={`/${lang}/detail/${color.slug}`}
    >
      <div
        className="carousel-image__image"
        style={{ backgroundImage: `url(${color.imageThumbSmall})` }}
      />
      <h6 className="carousel-image__label">{color.label}</h6>
    </Link>
  );
}

CarouselImage.propTypes = {
  color: PropTypes.object.isRequired,
  active: PropTypes.bool.isRequired,
};

export default CarouselImage;
