import RouteManager from "routes";
import { LanguageProvider } from "context/languageContext";
import "./styles/LandingMagliaC5.scss";

function LandingMagliaCrib5App() {
  return (
    <LanguageProvider>
      <RouteManager />
    </LanguageProvider>
  );
}

export default LandingMagliaCrib5App;
