import { useState } from "react";
import PropTypes from "prop-types";
import classNames from "classnames/bind";
import SamplingRequest from "components/SamplingRequest";
import ModalContext from "components/SamplingRequest/context";
import useToggle from "hooks/useToggle";
import {
  MODAL_CONTEXT_REQUEST,
  MODAL_CONTEXT_DOWNLOAD,
  MODAL_CONTEXT_CONTACT,
} from "./utils";
import "./styles/requests-section.scss";
import { useLocation } from "react-router-dom";

function RequestsSection({ refProp, mobile, isSticky }) {
  const [toggable, toggle] = useToggle();
  const { pathname } = useLocation();
  const [context, setContext] = useState(MODAL_CONTEXT_CONTACT);

  return (
    <>
      <div
        ref={refProp}
        className={classNames({
          "hero-links": true,
          web: !mobile,
          mobile: mobile,
          "on-top": !isSticky,
          "hero-links--detail": pathname.includes("detail") && !isSticky,
        })}
      >
        <ul>
          <li
            onClick={() => {
              setContext(MODAL_CONTEXT_REQUEST);
              toggle();
            }}
          >
            <button type="button">{MODAL_CONTEXT_REQUEST.title}</button>
          </li>
          <li
            onClick={() => {
              setContext(MODAL_CONTEXT_DOWNLOAD);
              toggle();
            }}
          >
            <button type="button">{MODAL_CONTEXT_DOWNLOAD.title}</button>
          </li>
          <li
            onClick={() => {
              setContext(MODAL_CONTEXT_CONTACT);
              toggle();
            }}
          >
            <button type="button">{MODAL_CONTEXT_CONTACT.title}</button>
          </li>
        </ul>
      </div>
      <ModalContext.Provider value={context}>
        <SamplingRequest isOpen={toggable} toggle={toggle} />
      </ModalContext.Provider>
    </>
  );
}

RequestsSection.propTypes = {
  mobile: PropTypes.bool,
  isSticky: PropTypes.bool,
  refProp: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.object }),
  ]),
};

export default RequestsSection;
