import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import "./styles/paragraph.scss";

function Paragraph({ className }) {
  return (
    <div className={`paragraph ${className}`}>
      <h4 className="mb-3 text-uppercase">
        <FormattedMessage
          id="Maglia.Paragraph.title"
          defaultMessage="the new era in textile-looking coated fabrics is now crib 5 and am18 certified"
        />
      </h4>
      <p className="mb-lg-3 description description--light">
        <FormattedMessage
          id="Maglia.Paragraph.subtitle"
          defaultMessage="Immersed through a colour experience, Spradling® has created a new Maglia ™ C5 colour range. We want you to blend with nature, we want you to go back to the essential. The essence of inspiring, creating, feeling, building and caring."
          values={{
            sup: (...chunks) => <sup>{chunks}</sup>,
            br: <br />,
          }}
        />
      </p>
    </div>
  );
}

Paragraph.propTypes = {
  className: PropTypes.string,
};

export default Paragraph;
