import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import { Container, Row, Col, Modal, ModalHeader, ModalBody } from "reactstrap";
import logo from "./img/logo_ecosense.svg";
import iso14001 from "./img/iso14001.svg";
import iso50001 from "./img/iso50001.svg";
import iso45001 from "./img/iso45001.svg";
import rohs from "./img/rohs.svg";
import reach from "./img/reach.svg";
import carbon from "./img/carbon.svg";
import greenguardGold from "./img/greenguard-gold.svg";
import indoorAirQuality from "./img/indoor-air-quality.svg";
import phthalateFree from "./img/phthalate-free.svg";
import "./styles/detailModal.scss";

const DetailModal = ({ isOpen, toggle }) => (
  <Modal
    className="modal-lg sampling-request-modal"
    scrollable
    isOpen={isOpen}
    toggle={toggle}
  >
    <ModalHeader className="custom-modal-header border-0" toggle={toggle} />
    <ModalBody className="ecosense-modal">
      <Container>
        <Row className="mx-lg-3 px-lg-3">
          <Col md={12}>
            <div className="mx-3 text-center">
              <img
                className="modal-logo-ecosense mb-5"
                src={logo}
                alt="logo ecosense"
              />
            </div>
          </Col>
        </Row>
        <Row className="mx-lg-3 px-lg-3">
          <Col md={12}>
            <h6 className="modal-text text-justify">
              <FormattedMessage
                id="Ecosense.Modal.description"
                defaultMessage="At Spradling<sup>®</sup>  Group, beyond beautifying spaces and meeting the highest requirements, we are committed to the future by positively impacting the environment, people, and communities around us.{br}{br}For this reason, we gave life to EcoSense<sup>®</sup> , a brand that represents our efforts to minimise our environmental footprint through sustainable product innovation and production processes, in both Costa Rica and Colombia."
                values={{
                  sup: (...chunks) => <sup>{chunks}</sup>,
                  br: <br />,
                }}
              />
            </h6>
          </Col>
        </Row>
        <Row className="align-items-center justify-content-center my-3 mx-lg-3 px-lg-3">
          <Col xs={3} sm={2} md={2} className="text-center">
            <img
              className="my-2 certs"
              height={32}
              src={iso14001}
              alt="iso14001"
            />
          </Col>
          <Col xs={3} sm={2} md={2} className="text-center">
            <img
              className="my-2 certs"
              height={32}
              src={iso45001}
              alt="iso45001"
            />
          </Col>
          <Col xs={3} sm={2} md={2} className="text-center">
            <img
              className="my-2 certs"
              height={32}
              src={iso50001}
              alt="iso50001"
            />
          </Col>
          <Col xs={3} sm={2} md={2} className="text-center">
            <img className="my-2 certs" height={32} src={rohs} alt="rohs" />
          </Col>
        </Row>
        <Row className="align-items-center justify-content-center my-3 mx-lg-3 px-lg-3">
          <Col xs={4} sm={3} md={2} className="text-center">
            <img
              className="my-2 certs-v2"
              height={32}
              src={reach}
              alt="reach"
            />
          </Col>
          <Col xs={4} sm={3} md={2} className="text-center">
            <img
              className="my-2 certs-v2"
              height={32}
              src={carbon}
              alt="carbon"
            />
          </Col>
          <Col xs={4} sm={3} md={2} className="text-center">
            <img
              className="my-2 certs-v3"
              src={phthalateFree}
              height={32}
              alt="phthalate free"
            />
          </Col>
          <Col xs={4} sm={3} md={2} className="text-center">
            <img
              className="my-2 certs-v3"
              height={64}
              src={greenguardGold}
              alt="greenguard gold"
            />
          </Col>
          <Col xs={4} sm={3} md={2}>
            <img
              className="my-2 certs-v3"
              height={64}
              src={indoorAirQuality}
              alt="indoor air auality"
            />
          </Col>
        </Row>
        <Row className="mx-lg-3 px-lg-3">
          <Col md={12} className="text-center">
            <h3 className="color-very-dark-cyan text-center mb-4">
              <FormattedMessage
                id="Ecosense.Modal.subtitle"
                defaultMessage="Sustainability Initiatives"
                values={{
                  sup: (...chunks) => <sup>{chunks}</sup>,
                }}
              />
            </h3>
            <h6 className="modal-text text-justify">
              <FormattedMessage
                id="Ecosense.Modal.protectingAir"
                defaultMessage="<strong>Protecting the air:</strong> We are a certified Carbon-neutral company. We reduce and offset 100% of our production's footprint."
                values={{
                  strong: (...chunks) => <strong>{chunks}</strong>,
                }}
              />
            </h6>
            <h6 className="modal-text text-justify">
              <FormattedMessage
                id="Ecosense.Modal.conservingWater"
                defaultMessage="<strong>Conserving water:</strong> We treat 100% of the wastewater. The water consumption necessary for the process is carried out efficiently using a closed circuit."
                values={{
                  strong: (...chunks) => <strong>{chunks}</strong>,
                }}
              />
            </h6>
            <h6 className="modal-text text-justify">
              <FormattedMessage
                id="Ecosense.Modal.managingWaste"
                defaultMessage="<strong>Managing our waste:</strong> Waste produced during our production processes is carefully managed following Government directives: We take advantage of the fabric scraps from our textile process for machine cleaning and maintenance. We have designed products that are developed from our own production waste. We are certified in ISO 14001 in our two facilities."
                values={{
                  strong: (...chunks) => <strong>{chunks}</strong>,
                  sup: (...chunks) => <sup>{chunks}</sup>,
                }}
              />
            </h6>
            <h6 className="modal-text text-justify">
              <FormattedMessage
                id="Ecosense.Modal.efficientEnergy"
                defaultMessage="<strong>Efficient use of energy:</strong> We have taken steps to increase efficiency and use cleaner forms of energy in our procedures. Almost 100% of the electrical energy that supplies both facilities comes from clean sources. We have photovoltaic systems in both plants (+ 760 solar panels). We have incorporated innovative, highly efficient electric motors that reduce the consumption of electrical energy. We are ISO 50001 certified."
                values={{
                  strong: (...chunks) => <strong>{chunks}</strong>,
                  sup: (...chunks) => <sup>{chunks}</sup>,
                }}
              />
            </h6>
            <h6 className="modal-text text-justify">
              <FormattedMessage
                id="Ecosense.Modal.socialSustainability"
                defaultMessage="<strong>Social Sustainability:</strong> Organised, structured and regularly monitored programs exist for our employees to guarantee that their betterment goes hand-in-hand with the company's progress. We improve our people's quality of education, generate spaces for recreation and learning that involve their families, supporting them to acquire their own home or improve their current conditions."
                values={{
                  strong: (...chunks) => <strong>{chunks}</strong>,
                }}
              />
            </h6>
          </Col>
        </Row>
        <div className="text-center">
          <button
            className="btn btn-dark rounded-pill mt-5 px-5"
            onClick={toggle}
          >
            <FormattedMessage id="Ecosense.close" defaultMessage="Close" />
          </button>
        </div>
      </Container>
    </ModalBody>
  </Modal>
);

DetailModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  toggle: PropTypes.func.isRequired,
};

export default DetailModal;
